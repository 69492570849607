import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Layout from '../../components/Layout';
import LayoutContext from '../../context/LayoutContext';
import PrivacyZh from './PrivacyZh';
import PrivacyEn from './PrivacyEn';

const Container = styled.div`
  h1 {
    font-weight: bold;
    padding: 20px 0;
  }

  font-size: 12px;
  color: ${(props) => (props.theme.name === 'men' ? '#ffffff' : '#000000')};
  table {
    color: ${(props) => (props.theme.name === 'men' ? '#ffffff' : '#000000')};
  }

  em {
    font-style: normal;
  }

  .header-title {
    font-size: 12px;
    border-bottom: 1px solid #ccc;
    padding: 8px 0;
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 1rem;
  }

  .page-privacy {
    @media screen and (max-width: 767px) {
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 20px;
    }

    h1,
    h2 {
      font-size: 12px;
      border-bottom: 1px solid;
      border-bottom-color: ${(props) =>
        props.theme.name === 'men' ? '#ffffff' : '#ccc'};
      padding: 8px 0;
      margin-bottom: 0.5rem;
      color: ${(props) => (props.theme.name === 'men' ? '#ffffff' : '#000000')};
    }
  }

  a {
    color: ${(props) => (props.theme.name === 'men' ? '#23bbd2' : '#007bff')};
  }
`;

const Privacy = () => {
  const { t } = useTranslation();
  const layoutProps = useContext(LayoutContext);
  const gender = layoutProps.pageContext.gender;
  const { isMobileView } = useSelector((state) => state.common);

  return (
    <Layout
      seoSettings={{ title: t('privacy_policy_&_pics') }}
      fullwidth={isMobileView}
    >
      <Container>
        {layoutProps.pageContext.locale === 'zh' ? (
          <PrivacyZh gender={gender} isMobileView={isMobileView} />
        ) : (
          <PrivacyEn gender={gender} isMobileView={isMobileView} />
        )}
      </Container>
    </Layout>
  );
};

export default Privacy;
