import React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import Link from '../../components/Link';

const PrivacyEn = ({ gender, isMobileView }) => {
  const genderPath = gender === 'men' ? '/men' : '';
  return (
    <div>
      {isMobileView ? (
        <PageTitle shadow={gender === 'men' ? true : false}>
          {gender === 'men' ? (
            <p>
              L’OREAL HONG KONG LIMITED <br />
              PRIVACY STATEMENT
            </p>
          ) : (
            'PRIVACY POLICY & PICS'
          )}
        </PageTitle>
      ) : (
        <h1>
          {gender === 'men' ? (
            <p>
              L’OREAL HONG KONG LIMITED <br />
              PRIVACY STATEMENT
            </p>
          ) : (
            'PRIVACY POLICY & PICS '
          )}
        </h1>
      )}
      {gender === 'men' ? (
        <div className="bootstrap page-privacy">
          <strong>
            L’Oreal’s ambition is to be an exemplary corporate citizen. We place
            great value on honesty and clarity and we are committed to building
            strong and lasting relationships with our customers based on trust
            and mutual benefit. In line with this philosophy, safeguarding your
            privacy is essential to us. We therefore encourage you to read this
            Privacy Statement so that you are familiar with the way we collect
            and process your personal data. <br />
            <br />
            LIST OF BRANDS UNDER L’OREAL HONG KONG LIMITED:
          </strong>
          <br />
          <br />
          <table class="brand">
            <tbody>
              <tr>
                <td>Biotherm</td>
                <td>Clarisonic</td>
                <td>Diesel</td>
                <td>Essie</td>
                <td>Garnier</td>
                <td>Giorgio Armani</td>
              </tr>
              <tr>
                <td>Helena Rubinstein</td>
                <td>Kérastase</td>
                <td>Kiehl's</td>
                <td>La Roche-Posay</td>
                <td>Lancôme</td>
                <td>L'Oreal Paris</td>
              </tr>
              <tr>
                <td>L'Oreal Professionnel</td>
                <td>Maison Martin Margiela</td>
                <td>Matrix</td>
                <td>Maybelline</td>
                <td>Pureology </td>
                <td>Ralph Lauren</td>
              </tr>
              <tr>
                <td>Roger &amp; Gallet</td>
                <td>shu uemura</td>
                <td>shu uemura Art of Hair</td>
                <td>SkinCeuticals</td>
                <td>Sanoflore</td>
                <td>Vichy</td>
              </tr>
              <tr>
                <td>Viktor &amp; Rolf</td>
                <td>Yves Saint Laurent Beauté</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <strong>WHAT DOES THIS PRIVACY STATEMENT APPLY TO? </strong>
          <br />
          This Privacy Statement applies to all L’Oreal Hong Kong Limited
          (‘L’Oreal HK’) brands, subsidiary or affiliate companies. <br />
          <br />
          This Privacy Statement also applies to marketing and promotional
          initiatives, applications, websites, online games and any other online
          initiatives of L’Oreal HK (including those managed and / or controlled
          by L’Oreal HK) and which collects personal identifiable information ("
          <strong>Media</strong>"). This Privacy Statement does not apply to
          third party material or websites, including those that may be
          mentioned or referred to on our Media via a link or other similar
          means, as we are not responsible for the privacy practices or content
          of third party websites, but we do encourage you to read the terms and
          conditions and privacy policy of any other website that you visit.{' '}
          <br />
          <br />
          We may occasionally modify this Privacy Statement and shall post those
          changes online so that you are aware of what information we collect,
          how we use it, and under what circumstances we disclose it. We shall
          let you know via the homepage when this happens. Do please therefore
          check this policy frequently to read the changes. <br />
          <br />
          <br />
          <h1>WHAT INFORMATION DO WE COLLECT FROM YOU? </h1>
          <br />
          You are welcome to visit our stores and to browse our websites without
          revealing personally identifiable information. If you do choose to
          disclose personally identifiable information, we may collect and
          process all or part of the following information when you elect to use
          our Media, for example, data that you provide when: <br />
          <br />
          (i) filling in forms (e.g. to become a registered user or member of
          any L’Oreal HK brand, or if you are interested in any particular
          online promotion you may be required to provide contact information
          via our Media); <br />
          <br />
          (ii) completing surveys (e.g. for research and statistical purposes);{' '}
          <br />
          <br />
          (iii) uploading information (e.g. when using social networking media
          or when you supply information to us in connection with any online
          promotion); <br />
          <br />
          (iv) subscribing to online services (i.e. brand initiatives or
          applications and social network pages); or <br />
          <br />
          (v) addressing our correspondence, including:- <br />
          <br />
          <div style={{ marginLeft: '25px' }}>
            *name; <br />
            *email address; <br />
            *gender; <br />
            *phone number; <br />
            *postal address; <br />
            *age / day and month of birth; <br />
            *information about your perception of our products; <br />
            *payment details (i.e. for on e-commerce Media); <br />
            *any specific request you would like us to address (i.e. for record
            purposes); <br />
            *photos or videos of you that you would like to share. <br />
          </div>
          <br />
          <br />
          <br />
          <h1>WEBSITES – DO WE COLLECT ANONYMOUS DATA? </h1>
          <br />
          If you use our websites or related online facilities we may collect
          your IP address and your browser type for system administration and/or
          statistical purposes and without identifying any other individual
          information. A ‘cookie’ may be placed on your device in order to allow
          you to use the website. <br />
          <br />
          A ‘cookie’ is a small piece of data which may be downloaded and stored
          by your device whenever you access our Media. Cookies allow L’Oreal HK
          to recognize your device while you are viewing our Media as a previous
          user but this does not enable us to collect any personal identifiable
          information from your device. <br />
          <br />
          We will mainly use cookies to track activity on our Media and to
          recognize you the next time you visit our Media and to display
          contents that may match your interests. <br />
          <br />
          Web browsers are usually set by default to accept cookies but you may
          easily change this by modifying the settings of your browser and/or
          your own device whenever viewing content over the internet. However,
          please note that should you choose to disable cookies, you may be
          unable to use parts of our Media and this may affect your enjoyment
          and use of our Media. <br />
          <br />
          <br />
          <h1>HOW DO WE USE YOUR DATA? </h1>
          <br />
          We principally only use your personal identifiable information to keep
          you connected with us by regularly sending you news and information on
          our products, our brands, our initiatives, brand policies and/or
          related updates, to understand your preferences and to respond to any
          request or enquiry (collectively referred to as “
          <strong>Marketing Communication</strong>”). <br />
          <br />
          We also use your information to improve your experience with us,
          understanding your interests in our Media and its contents, managing
          your memberships or online account, validating your enrolments to our
          initiatives, processing your payments, ensuring that our Media is
          presented in the most effective manner for you and for your computer,
          protecting against potential fraudulent transactions and also to
          maintain the security and integrity of our Media systems. <br />
          <br />
          <br />
          <h1>HOW CAN YOU ACCESS YOUR PERSONAL DATA? </h1>
          <br />
          You may contact us at any time by post or email (please refer to the
          contact details indicated in the terms of use of the Media you are
          using) to find out what data we have concerning you. <br />
          <br />
          If you identify any mistakes in the data or if you find it incomplete
          or out-dated, you may also require from us that we correct it,
          complete it or clarify it. <br />
          <br />
          You may also require us to erase any data we have concerning you.
          However, please note that we may keep records of your data for
          archiving purposes (especially when required by law). <br />
          <br />
          <br />
          <h1>DO WE SHARE YOUR INFORMATION? </h1>
          <br />
          Other than as set out in this Privacy Statement or unless we have your
          prior consent, we will not share your information with anyone outside
          of L’Oreal HK, L’Oreal China or our designated service providers, for
          example our web agencies who may sometimes be asked to manage on our
          behalf some online initiatives. <br />
          <br />
          Our service providers only access your data to operate the services
          that we ask them to perform. We also ask these business partners to
          always act in compliance with applicable privacy laws and to pay
          attention to the confidentiality of your data. If you do not wish to
          supply any personally identifiable information, you are welcome to
          refrain from doing so. We will not sell your personal identifiable
          information to any third parties and such data is retained only for as
          long as is necessary to fulfil the purpose for which the data was
          collected. <br />
          <br />
          As we continue to develop our business, we might sell certain of our
          assets. In such event, customer information including personally
          identifiable information will generally be one of the transferred
          corporate assets. By using and/or submitting your data to us or via
          our Media, you consent and agree that all such data may be transferred
          in these circumstances. <br />
          <br />
          <br />
          <h1>IS YOUR DATA KEPT SECURE? </h1>
          <br />
          We aim at always keeping your data safe and secure as possible. In
          this perspective we deploy multiple security technologies in order to
          prevent as much as possible any alteration, loss or unauthorized
          access to your data. <br />
          <br />
          On our e-commerce Media, we handle your payment information with
          industry standard methods (such as PCI DSS). <br />
          <br />
          Please be careful when submitting personal identifiable information
          online as no data transmission via the internet can be guaranteed as
          100% secure. As a result, we cannot guarantee the complete security of
          any information that you may supply or transmit to us over the
          internet. <br />
          <br /> <br />
          <h1>LANGUAGE &amp; GOVERNING LAW</h1>
          <br />
          L’Oreal HK is committed to handling your personal identifiable
          information in full compliance with the Personal Data (Privacy)
          Ordinance (Cap.486). <br />
          <br />
          This Privacy Statement is governed by and interpreted under the laws
          of Hong Kong and you agree to submit to the non-exclusive jurisdiction
          of Hong Kong to resolve any dispute arising from the use of our
          services and Media. <br />
          <br />
          A Chinese version of this Privacy Statement is available. In the event
          of discrepancy, the English version shall prevail. <br />
          <br />
          <br />
          <h1>CHOICE / OPT-OUT</h1>
          <br />
          In order to provide our services to you, L’Oreal HK will use your
          personal identifiable information only for Marketing Communications
          which shall necessarily include sending you communications related to
          your transactions, security or the administration of our Media. <br />
          <br />
          From time to time, L’Oreal HK (or a L’Oreal HK affiliated brand or
          entity) may also send you other messages or updates, promotions and
          other related activities based on your consent and stated preferences.{' '}
          <br />
          <br />
          If at any point in time you no longer wish to receive any L’Oreal
          Marketing Communication, please send us an email at:{' '}
          <a href="mailto: dppo@hkloreal.com">dppo@hkloreal.com</a>. <br />
          <br />
          <br />
          <h1>HOW CAN YOU CONTACT US? </h1>
          <br />
          If you have any queries with regard to this Privacy Statement, please
          contact our Data Protection Officer at{' '}
          <a href="mailto: dppo@hkloreal.com">dppo@hkloreal.com</a>. <br />
          <br />
          Alternatively, you may write or send your data access request to:{' '}
          <br />
          <br />
          Data Protection Officer
          <br />
          L’Oreal Hong Kong Limited
          <br />
          34/F., Tower One, <br />
          Times Square, <br />
          Causeway Bay, Hong Kong. <br />
          <br />
        </div>
      ) : (
        <div className="bootstrap page-privacy">
          <div className="header-container">
            <h2 className="header-title">PRIVACY POLICY</h2>
          </div>
          <p align="left">
            L’Oréal’s ambition is to be an exemplary corporate citizen to help
            make the world a more beautiful place. We place great value on
            honesty and clarity and we are committed to building a strong and
            lasting relationship with our consumers based on trust and mutual
            benefit. Part of this commitment means safeguarding and respecting
            your privacy and your choices. Respecting your privacy is essential
            to us. This is why we set out “Our Privacy Promise” and our full
            Privacy Policy below.
            <br />
            <br />
            OUR PRIVACY PROMISE
          </p>
          <ol>
            <li>We respect your privacy and your choices.</li>
            <li>
              We make sure that privacy and security are embedded in everything
              we do.
            </li>
            <li>
              We do not send you marketing communications unless you have asked
              us to. You can change your mind at any time.
            </li>
            <li>We never offer or sell your data.</li>
            <li>
              We are committed to keeping your data safe and secure. This
              includes only working with trusted partners.
            </li>
            <li>
              We are committed to being open and transparent about how we use
              your data.
            </li>
            <li>
              We do not use your data in ways that we have not told you about.
            </li>
            <li>
              We respect your rights, and always try to accommodate your
              requests as far as is possible, in line with our own legal and
              operational responsibilities.
            </li>
          </ol>
          <div>
            For more information about our privacy practices, below we set out
            what types of personal data we may receive from you directly or from
            your interaction with us, how we may use it, who we may share it
            with, how we protect it and keep it secure, and your rights around
            your personal data. Of course all situations may not apply to you.
            This Privacy Policy gives you an overview of all possible situations
            in which we could interact together. <br />
            <br />
            The more you interact with us, the more you let us know you and the
            more we are able to offer you tailored services.
            <br />
            <br />
            When you share personal data with us or when we collect personal
            data about you, we use it in line with this Policy. Please read this
            information and our Q&amp;A page (if any) carefully.
            <br />
            <br />
            WHAT WILL YOU FIND IN THIS PRIVACY POLICY?
            <br />
            <a href="#who-are-we">Who are we?</a>
            <br />
            <a href="#what-is-personal-data">What is personal data?</a>
            <br />
            <a href="#what-data-do-we-collect">
              What data do we collect from you and how do use it?
            </a>
            <br />
            <div style={{ marginLeft: '40px' }}>
              <a href="#how-do-we-collect">
                How do we collect or receive your data?
              </a>
              <br />
              <a href="#automated-decision-making">Automated Decision Making</a>
              <br />
              <a href="#profiling">Profiling</a>
              <br />
              <a href="#who-may-access-your-personal-data">
                Who may access your personal data?
              </a>
              <br />
              <a href="#where-we-store-your-personal-data">
                Where we store your personal data?
              </a>
              <br />
              <a href="#how-long-do-we-keep-your-personal-data">
                How long do we keep your personal data?
              </a>
              <br />
              <a href="#is-my-Personal-data-secure">
                Is my Personal data secure?
              </a>
              <br />
              <a href="#links-to-third-party-sites">
                Links to third party sites and social login
              </a>
              <br />
              <a href="#social-media">
                Social media and user generated content
              </a>
              <br />
            </div>
            <a href="#your-rights">Your rights and choices</a>
            <br />
            <a href="#contact">Contact </a>
            <br />
            Please note that you must be at least 18 years old or older to use our services, or older where the terms for a specific service require this.
          </div>
          <h1 id="who-are-we">
            <p name="_Toc499630191">WHO WE ARE</p>
          </h1>
          <p>
            BIOTHERM is a part of the L’Oreal Hong Kong Limited brand portfolio.
            L’Oreal Hong Kong Limited is responsible for the personal data that
            you share with us. When we say “L’Oréal”, “us”, “our” or “we”, this
            is who we are referring to. L’Oréal is a “data user” for the
            purposes of the <b>Personal Data (Privacy) Ordinance</b> (Cap. 486).
          </p>
          <p>
            Please see the “Contact Us” section for our contact details.
            <br />
            <br />
            L’Oréal represents several different brands and products. For more
            information on L’Oréal, and the brands it represents, please see{' '}
            <a href="http://www.loreal.hk/en-hk/">
              http://www.loreal.hk/en-hk/
            </a>
            .
          </p>
          <p>
            L’Oréal is part of the L’Oréal Group, which operates in 140
            countries around the world. For details on the L’Oréal Group, please
            see{' '}
            <a href="http://www.loreal.com/group">
              http://www.loreal.com/group
            </a>
            .
          </p>
          <h1 id="contact">
            <p name="_Toc499630204">CONTACT</p>
          </h1>
          <p>
            If you have any questions or concerns about how we treat and use
            your personal data, or would like to exercise any of your rights
            above, please contact us at{' '}
            <a href="mailto:biotherm.csc@loreal.com">biotherm.csc@loreal.com</a>
            or by writing to us at:
          </p>
          <p>
            Customer Care Department
            <br />
            L’Oreal Hong Kong Limited
            <br />
            35/F., Sun Hung Kai Centre
            <br />
            30 Harbour Road
            <br />
            Wan Chai, Hong Kong SAR
            <br />
            Attn: BIOTHERM
          </p>
          <p>
            You may also contact{' '}
            <a href="mailto:privacy.corphk@loreal.com">
              privacy.corphk@loreal.com
            </a>{' '}
            for any questions related to the processing of your personal data.
            <br />
            <br />
            <br />
          </p>
          <p className="text-center" style={{ fontSize: '20px' }}>
            <strong>BIOTHERM</strong>
            <br />
            <strong>PRIVACY POLICY</strong>
          </p>
          <p></p>
          <h1 id="what-is-personal-data">
            <p name="_Toc499630192">WHAT IS PERSONAL DATA?</p>
          </h1>
          <p>
            “Personal data” means any information or pieces of information that
            could identify you either directly (e.g. your name) or indirectly
            (e.g. through pseudonymized data such as a unique ID number). This
            means that personal data includes things like email/home
            addresses/mobile phone, usernames, profile pictures, personal
            preferences and shopping habits, user generated content, financial
            information, and welfare information. It could also include unique
            numerical identifiers like your computer’s IP address or your mobile
            device’s MAC address, as well as cookies.
          </p>
          <h1 id="what-data-do-we-collect">
            <p name="_Toc499630193">
              WHAT DATA DO WE COLLECT FROM YOU AND HOW DO WE USE IT?
            </p>
          </h1>
          <p>
            L'Oréal believes that you, the consumer, are at the heart of what we
            do. We love hearing from you, learning about you, and creating and
            delivering products that you enjoy. And we know that many of you
            love talking to us. Because of this, there are many ways that you
            might share your personal data with us, and that we might collect
            it.
          </p>
          <h2 id="how-do-we-collect">
            <p name="_Toc499630194">How do we collect or receive your data?</p>
          </h2>
          <p>
            We might collect or receive data from you via our websites, forms,
            apps, devices, L’Oréal products or brands pages on social media or
            otherwise. Sometimes you give this to us directly (e.g. when you
            create an account, when you contact us, when you purchase from our
            websites/apps or stores/beauty salon), sometimes we collect it (e.g.
            using cookies to understand how you use our websites/apps) or
            sometimes we receive your data from other third parties, including
            other L’Oréal Group entities.
            <br />
            When we collect data, we indicate the mandatory fields via asterisks
            where such data is necessary for us to:
          </p>
          <ul>
            <li>
              perform our contract with you (e.g. to deliver the products you
              have purchase on our websites/apps);
            </li>
            <li>
              provide you with the service you have asked for (e.g. to provide
              you with a newsletter, birthday offers or account status); or
            </li>
            <li>comply with legal requirements (e.g. invoicing). </li>
          </ul>
          <p>
            If you do not provide the data marked with an asterisk, this may
            affect our ability to provide the products and services.
            <br />
            We set out further details in the table below, explaining:
          </p>
          <ol>
            <li>
              <strong>
                During what interaction your data may be provided or collected?
              </strong>{' '}
              This column explains what activity or situation you are involved
              in when we use or collect your data. For example, whether you are
              making a purchase, signing up to a newsletter, or browsing a
              website/app.
            </li>
            <li>
              <strong>
                What personal data may we receive from you directly or resulting
                from your interaction with us?{' '}
              </strong>
              This column explains what types of data we may collect about you
              depending on the situation.
            </li>
            <li>
              <strong>How and why we may use it? </strong>This column explains
              what we may do with your data and the purposes for collecting it.
            </li>
            <li>
              <strong>
                What is the legal basis for using your personal data?{' '}
              </strong>{' '}
              This column explains the reason we may use your data.
              <br />
              Depending on the purpose for which the data is used, the legal
              basis for the processing of your data can be:
              <ul>
                <li>
                  Your <strong>consent;</strong>
                </li>
                <li>
                  Our <strong>legitimate interest</strong>, which can be:
                  <ul>
                    <li>
                      Improvement of our products and services:more
                      specifically, our business interests to help us better
                      understand your needs and expectations and therefore
                      improve our services, websites / Apps / devices, products
                      and brands for our consumers’ benefit.
                    </li>
                    <li>
                      Fraud prevention: to ensure payment is complete and free
                      from fraud and misappropriation.
                    </li>
                    <li>
                      Securing our tools: to keep tools used by you (our
                      websites/Apps/devices) safe and secure and to ensure they
                      are working properly and are continually improving.
                    </li>
                  </ul>
                </li>
                <li>
                  The <strong>performance of a contract: </strong>more
                  specifically to perform the services you request from us; or
                </li>
                <li>
                  <strong>Legal grounds</strong> where a processing is required
                  by law.
                </li>
              </ul>
            </li>
          </ol>
          <table
            border="0"
            cellPadding="0"
            cellSpacing="0"
            className="table table-bordered table-responsive"
            width="0"
          >
            <tbody>
              <tr>
                <td colSpan="4" valign="top">
                  <p>
                    <u>
                      Information overview on your interactions with us and
                      their consequences on your data
                    </u>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <p>
                    <strong>
                      <em>
                        During which interactions may you provide and we may
                        collect your data?
                      </em>
                    </strong>
                  </p>
                </td>
                <td valign="top">
                  <p>
                    <strong>
                      <em>
                        What personal data may we receive from you directly or
                        resulting from your interaction with us?
                      </em>
                    </strong>
                  </p>
                </td>
                <td valign="top">
                  <p>
                    <strong>
                      <em>How and why we may use your data?</em>
                    </strong>
                  </p>
                </td>
                <td valign="top">
                  <p>
                    <strong>
                      <em>
                        What is the legal basis for using your personal data?
                      </em>
                    </strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td rowSpan="3" valign="top">
                  <p>
                    <strong>Account Creation and management</strong>
                  </p>

                  <p>
                    Information collected during the creation of an account on
                    L’Oréal websites/apps, through a social media login, or in
                    store.
                  </p>
                </td>
                <td rowSpan="3" valign="top">
                  <p>
                    Depending on how much you are interacting with us, those
                    data may include:
                  </p>

                  <ul>
                    <li>name and surname; </li>
                    <li>gender;</li>
                    <li>email address;</li>
                    <li>address;</li>
                    <li>phone number;</li>
                    <li>photo;</li>
                    <li>birthday or age range;</li>
                    <li>ID, username, and password;</li>
                    <li>personal description or preferences; </li>
                    <li>order details; and</li>
                    <li>
                      social media profile (where you use social login or share
                      this personal data with us).
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <p>To:</p>

                  <ul>
                    <li>manage your orders;</li>
                    <li>
                      manage any competitions, promotions, surveys or lucky
                      draws you choose to enter;
                    </li>
                    <li>
                      respond to your questions and otherwise interact with you
                      ;
                    </li>
                    <li>offer you a loyalty program;</li>
                    <li>allow you to manage your preferences;</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Performance of a contract</strong>
                      <p>
                        To provide you with the service you requested (e.g.
                        create an account, complete a survey, or purchasing a
                        product).
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>
                      send you marketing communications (where you have asked us
                      to) which may be tailored to your “profile” (i.e. based on
                      the personal data we know about you and your preferences);
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Consent</strong>
                      <p>To send you direct marketing communications.</p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>
                      offer personalized services based on your beauty
                      characteristics;
                    </li>
                    <li>monitor and improve our websites/apps ;</li>
                    <li>run analytics or collect statistics; and</li>
                    <li>
                      secure our websites/apps and protect you and us against
                      fraud;
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Legitimate Interest</strong>
                      <p>
                        To ensure our websites/apps remain secure, to protect
                        them against fraud, and to help us better understand
                        your needs and expectations and therefore improve our
                        services, products and brands.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td rowSpan="3" valign="top">
                  <p>
                    <strong>
                      Newsletter and commercial communications subscription
                    </strong>
                  </p>
                </td>
                <td rowSpan="3" valign="top">
                  <p>
                    Depending on how much you are interacting with us, those
                    data may include:
                  </p>

                  <ul>
                    <li>email address;</li>
                    <li>name and surname; </li>
                    <li>personal description or preferences; and</li>
                    <li>
                      social media profile (where you use social login or share
                      this personal data with us).
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <p>To :</p>

                  <ul>
                    <li>
                      send you marketing communications (where you have asked us
                      to) which may be tailored to your “profile” based on the
                      personal data we know about you, and your preferences
                      (incl. location of your favourite store); and
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Consent</strong>
                      <p>To send you direct marketing communications.</p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>run analytics or collect statistics.</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Legitimate Interest</strong>
                      <p>
                        to tailor our marketing communications, understand their
                        effectiveness, and ensure you receive the most relevant
                        experience; and to help us better understand your needs
                        and expectations and therefore improve our services,
                        products and brands.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>
                      Keep an up to date suppression list if you have asked not
                      to be contacted;
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Legal grounds</strong>
                      <p>
                        To keep your details on a suppression list if you have
                        asked us not to send you any direct marketing anymore.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td rowSpan="2" valign="top">
                  <p>
                    <strong>Purchases and order management</strong>
                  </p>

                  <p>
                    Information collected during the purchase process made on
                    L’Oréal website/apps/social pages or in store
                  </p>
                </td>
                <td rowSpan="2" valign="top">
                  <p>
                    Depending on how much you are interacting with us, those
                    data may include:
                  </p>

                  <ul>
                    <li>name and surname; </li>
                    <li>email address;</li>
                    <li>address (delivery and invoicing);</li>
                    <li>phone number;</li>
                    <li>personal description or preferences; </li>
                    <li>
                      social media profile (where you use social login or share
                      this personal data with us);{' '}
                    </li>
                    <li>
                      transaction information including purchased products and
                      store location;
                    </li>
                    <li>payment and information; or</li>
                    <li>purchase history</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>To</p>

                  <ul>
                    <li>
                      contact you to finalize your order where you have saved
                      your shopping cart or placed products in your cart without
                      completing the checkout process;{' '}
                    </li>
                    <li>
                      inform you when a product you wanted to purchase is
                      available;
                    </li>
                    <li>
                      process and follow your order including delivering the
                      product to the address you indicated;{' '}
                    </li>
                    <li>
                      manage the payment of your order. To be noted, payment
                      information (credit card number / Paypal, Alipay or WeChat
                      Pay information / bank account details) are not collected
                      by us but directly by payment service providers;{' '}
                    </li>
                    <li>
                      manage any contact you have with us regarding your order;
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Performance of a contract:</strong>
                      <p>
                        To provide you with the service you requested
                        (purchase).
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>
                      secure the transactions against fraud. To be noted, we use
                      a third party provider’s solution to detect fraud and
                      ensure the payment is complete and made by you or someone
                      authorized by you;
                    </li>
                    <li>
                      enrich your profile if you place a purchase using your
                      account information;
                    </li>
                    <li>measure satisfaction;</li>
                    <li>manage any dispute relating to a purchase; and</li>
                    <li>for statistics purposes.</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Legitimate interest</strong>
                      <p>
                        To protect you and us from fraudulent transaction and to
                        ensure the payment is complete and free from fraud and
                        misappropriation.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td rowSpan="2" valign="top">
                  <strong>Online browsing</strong>
                  <br />
                  &nbsp;
                  <p>
                    Information collected by cookies or similar technologies (“
                    <strong>Cookies</strong>”*) as part of your browsing on
                    L’Oréal website / apps and/or on third-party website / apps.
                  </p>
                  <p>
                    For information on specific Cookies placed through a given
                    website/app, please consult the relevant cookie table.
                  </p>
                  <p>
                    * Cookies are small text files stored on your device
                    (computer, tablet or mobile) when you are on the Internet,
                    including on L’Oréal Group’s websites.
                  </p>
                </td>
                <td rowSpan="2" valign="top">
                  <p>
                    Depending on how much you are interacting with us, those
                    data may include:
                  </p>

                  <ul>
                    <li>data related to your use of our websites/apps: </li>
                    <li>where you came from;</li>
                    <li>login details;</li>
                    <li>pages you looked at;</li>
                    <li>videos you watched;</li>
                    <li>ads you click on or tap;</li>
                    <li>products you search for;</li>
                    <li>your location;</li>
                    <li>duration of your visit; and</li>
                    <li>products you selected to create your basket.</li>
                  </ul>

                  <p>Technical information:</p>

                  <ul>
                    <li>IP address;</li>
                    <li>browser information; and</li>
                    <li>device information.</li>
                  </ul>

                  <p>
                    A unique identifier granted to each visitor and the
                    expiration date of such identifier.
                  </p>
                </td>
                <td valign="top">
                  <p>
                    We use Cookies, where relevant, with other personal data you
                    have already shared with us (such as previous purchases, or
                    whether you’re signed up to our email newsletters) or the
                    following purposes:
                  </p>

                  <ul>
                    <li>
                      to allow proper functioning of our website/apps:
                      <ul>
                        <li>proper display of the content;</li>
                        <li>creation and remembering of a cart;</li>
                        <li>creation and remembering of your login; </li>
                        <li>interface personalisation such as language;</li>
                        <li>
                          parameters attached to your device including your
                          screen resolution, etc; and
                        </li>
                        <li>
                          improvement of our websites/apps, for example, by
                          testing new ideas;
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      to ensure the website/app is secure and safe and protect
                      you against fraud or misuse of our websites or services,
                      for example through performing troubleshooting;
                    </li>
                  </ul>

                  <ul>
                    <li>
                      to run statistics:
                      <ul>
                        <li>to avoid visitors being recorded twice; </li>
                        <li>
                          to know users’ reaction to our advertising campaigns;
                        </li>
                        <li>to improve our offers; and</li>
                        <li>to know how you discovered our websites / apps.</li>
                      </ul>
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Legitimate interest:</strong>:
                      <p>
                        To ensure we are providing you with websites / apps,
                        advertising and communications that are working properly
                        and are continually improving for cookies that are (i)
                        essential for the functioning of our websites / apps,
                        (ii) used to keep our websites/apps safe and secure.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>
                      to deliver online behavioural advertising:
                      <ul>
                        <li>
                          to show you online advertisements for products which
                          may be of interest to you, based on your previous
                          behaviour; and{' '}
                        </li>
                        <li>
                          to show you ads and content on social media platforms.
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      to tailor our services for you:
                      <ul>
                        <li>
                          to send you recommendations, marketing, or content
                          based on your profile and interests;{' '}
                        </li>
                        <li>
                          to display our websites/apps in a tailored way like
                          remembering your cart or login, your language, the
                          user-interface customization cookies (i.e. the
                          parameters attached to your device including your
                          screen resolution, font preference, etc); and
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      to allow sharing of our content on social media (sharing
                      buttons intended to display the site).
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Consent</strong>
                      <p>For all other cookies.</p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td rowSpan="3" valign="top">
                  <p>
                    <strong>Promotional operations</strong>
                  </p>

                  <p>
                    Information collected during a game, contests, promotional
                    offer, sample requests, surveys.
                  </p>
                </td>
                <td rowSpan="3" valign="top">
                  <p>
                    Depending on how much you are interacting with us, those
                    data may include:
                  </p>

                  <ul>
                    <li>name and surname;</li>
                    <li>email address;</li>
                    <li>phone number;</li>
                    <li>birth date;</li>
                    <li>gender;</li>
                    <li>address;</li>
                    <li>personal description or preferences; </li>
                    <li>
                      social media profile (where you use social login or share
                      this personal data with us); and
                    </li>
                    <li>
                      other information you have shared with us about yourself
                      (e.g. via your “My Account” page, by contacting us, or by
                      providing your own content such as photos or a review, or
                      a question via the chat function available on some
                      websites/apps, or by participating in a contest, game,
                      survey).
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      to complete tasks that you have asked us to, for example
                      to manage your participation in contests, games and
                      surveys, including to take into account your feedback and
                      suggestions;
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Performance of contract</strong>
                      <p>To provide you with the service you requested.</p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>for statistics purposes; and</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Legitimate Interest</strong>
                      <p>
                        To help us better understand your needs and expectations
                        and therefore improve our services, products and brands.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>
                      to send you marketing communications (where you have asked
                      us to)
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Consent</strong>
                      <p>To send you direct marketing communications.</p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td rowSpan="2" valign="top">
                  <p>
                    <strong>User Generated Content</strong>
                  </p>

                  <p>
                    Information collected when you submitted some content on one
                    of our social platforms or accepted the re-use of content
                    you posted on social media platforms by us.
                  </p>
                </td>
                <td rowSpan="2" valign="top">
                  <p>
                    Depending on how much you are interacting with us, those
                    data may include:
                  </p>

                  <ul>
                    <li>name and surname or alias;</li>
                    <li>email address;</li>
                    <li>photo;</li>
                    <li>personal description or preferences; </li>
                    <li>
                      social media profile (where you use social login or share
                      this personal data with us); and
                    </li>
                    <li>
                      other information you have shared with us about yourself
                      (e.g. via your “My Account” page, by contacting us, or by
                      providing your own content such as photos or a review, or
                      a question via the chat function available on some
                      websites/apps).
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      In accordance with the specific terms and conditions
                      accepted by you:
                      <ul>
                        <li>to post your review or content; and</li>
                        <li>to promote our products.</li>
                      </ul>
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Consent</strong>
                      <p>To reuse the content you posted online.</p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>For statistics purposes.</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Legitimate Interest</strong>
                      <p>
                        To help us better understand your needs and expectations
                        and therefore improve and promote our services, products
                        and brands.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td rowSpan="2" valign="top">
                  <p>
                    <strong>Use of Apps and devices</strong>
                  </p>

                  <p>
                    Information collected as part of your use of our Apps and/or
                    devices.
                  </p>
                </td>
                <td rowSpan="2" valign="top">
                  <p>
                    Depending on how much you are interacting with us, those
                    data may include:
                  </p>

                  <ul>
                    <li>name and surname;</li>
                    <li>email address;</li>
                    <li>location;</li>
                    <li>birth date;</li>
                    <li>personal description or preferences;</li>
                    <li>photo;</li>
                    <li>
                      welfare data including skin tone, skin/hair type; and
                    </li>
                    <li>geolocation. </li>
                  </ul>
                </td>
                <td valign="top">
                  <p>To</p>

                  <ul>
                    <li>
                      provide you with the service requested (for example,
                      virtually test our products, purchase our products through
                      the App or on related e-com websites; advice and
                      notifications regarding your sun exposure, your hair
                      routine);
                    </li>
                    <li>
                      analyse your welfare characteristics and recommend the
                      appropriate products (including bespoke products) and
                      routines;
                    </li>
                    <li>provide you product &amp; routine recommendations;</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Performance of a contract</strong>
                      <p>
                        To provide you with the service requested (including,
                        where needed, analysis by the research and innovation
                        team of the algorithm necessary to provide the service).
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>
                      for research and innovation by scientists within L’Oréal
                      Group;
                    </li>
                    <li>
                      for monitoring and improvement of our Apps and devices;
                      and
                    </li>
                    <li>for statistics purposes.</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Legitimate Interest</strong>
                      <p>
                        To always improve our products and services to match
                        your needs and expectations and for research and
                        innovation purposes.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td rowSpan="2" valign="top">
                  <p>
                    <strong>Enquiries</strong>
                  </p>

                  <p>
                    Information collected when you ask questions (e.g. through
                    our consumer care) relating to our brands, our products and
                    their use.
                  </p>
                </td>
                <td rowSpan="2" valign="top">
                  <p>
                    Depending on how much you are interacting with us, those
                    data may include:
                  </p>

                  <ul>
                    <li>name and surname;</li>
                    <li>phone number;</li>
                    <li>email address; and</li>
                    <li>
                      other information you have shared with us about yourself
                      in relation to your enquiry (which may include welfare and
                      health data).
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>To answer your enquiries;</li>
                    <li>
                      where needed, to connect you with the relevant services;{' '}
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Consent</strong>
                      <p>To process your enquiry.</p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>for statistics purposes; and</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Legitimate interest</strong>
                      <p>
                        To help us better understand our customers’ needs and
                        expectations and therefore improve our services,
                        products and brands.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">&nbsp;</td>
                <td valign="top">&nbsp;</td>
                <td valign="top">
                  <ul>
                    <li>
                      for post-market surveillance:
                      <ul>
                        <li>
                          to monitor and prevent any undesirable effect linked
                          to the use of our products;
                        </li>
                        <li>
                          to perform studies relating to the safe use of our
                          products; and
                        </li>
                        <li>
                          to perform and follow-up on corrective measures taken,
                          where needed.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Legal grounds</strong>
                      <p>
                        To comply with the legal obligation to monitor
                        undesirable effects of its products.
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <p>
                    <strong>Sponsorship</strong>
                  </p>
                </td>
                <td valign="top">
                  <p>
                    Depending on how much you are interacting with us, those
                    data may include:
                  </p>

                  <ul>
                    <li>name and surname;</li>
                    <li>phone number; and</li>
                    <li>email address.</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      To send information on our products and or information
                      tagged in a wish list to a person at another person’s
                      request.
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>Performance of a contract</strong>
                      <p>To process the request.</p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <strong>Legitimate interest</strong>

                      <p>To contact the person at another person’s request.</p>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <h2 id="automated-decision-making">
            <p name="_Toc499630195">Automated Decision Making</p>
          </h2>
          <p>
            For purposes of securing transactions placed through our
            websites/apps/devices against fraud and misappropriation, we use
            third party provider’s solution(s).The method of fraud detection is
            based on, for example, simple comparisons, association, clustering,
            prediction and outlier detections using intelligent agents, data
            fusion techniques and various data mining techniques.
          </p>
          <p>
            This fraud detection process may be completely automated or may
            involve human intervention where a person takes the final decision.
            In any case, we take all reasonable precautions and safeguards to
            limit access to your data.
          </p>
          <p>
            As a result of automatic fraud detection, you may (i) experience
            delay in the processing of your order / request whilst your
            transaction is being reviewed by us; and (ii) be limited or excluded
            from the benefit of a service if a risk of fraud is identified. You
            have the right to access information on which we base our decision.
            Please see “Your Rights and Choices” section below.
          </p>
          <h2 id="profiling">
            <p name="_Toc499630196">Profiling</p>
          </h2>
          <p>
            When we send or display personalised communications or content, we
            may use some techniques qualified as “profiling” (i.e. any form of
            automated processing of personal data consisting of using those data
            to evaluate certain personal aspects relating to a natural person,
            in particular to analyse or predict aspects concerning that natural
            person’s personal preferences, interests, economic situation,
            behaviour, location, health, reliability, or movements). This means
            that we may collect personal data about you in the different
            scenarios mentioned in the table above. We centralize this data and
            analyse it to evaluate and predict your personal preferences and/or
            interests.
            <br />
            Based on our analysis, we send or display communications and/or
            content tailored to your interests/needs.
          </p>
          <p>
            You have the right to object to the use of your data for “profiling”
            in certain circumstances. Please see “Your Rights and Choices”
            section below.
          </p>
          <h2 id="who-may-access-your-personal-data">
            <p name="_Toc499630197">Who may access your Personal data?</p>
          </h2>
          <p>
            <strong>
              We may share your personal data within L’Oréal Group to comply
              with our legal obligations, to prevent fraud and/or to secure our
              tools, to improve our products and services, or after having
              obtained your consent to do so.{' '}
            </strong>
            <br />
            Depending on the purposes for which they were collected, and only on
            a need-to-know basis some of your personal data may be accessed by
            L’Oréal Group entities worldwide, where possible in a pseudonimized
            way (not allowing direct identification), and where necessary to
            provide you with requested services.
          </p>
          <p>
            We may also share your personal data in a pseudonimized way (not
            allowing direct identification) with L’Oréal Research &amp;
            Innovation scientists, including those located outside of your
            country, for research and innovation purposes.
          </p>
          <p>
            Where permitted, we may also share some of your personal data
            including those collected through Cookies between our brands to
            harmonize and update the information you share with us, to perform
            statistics based on your characteristics and to tailor our
            communications.
          </p>
          <p>
            Please visit the L’Oréal group website, for further details on the{' '}
            <a href="http://www.loreal.com/group">L'Oréal Group</a>, its{' '}
            <a href="http://www.loreal.com/brand">brands</a>
            and its{' '}
            <a href="http://www.loreal.com/group/our-activities/l%E2%80%99or%C3%A9al-around-the-world">
              locations
            </a>
            .
          </p>
          <strong>
            We may share your personal data for marketing purposes with third
            party or entities of the L’Oréal Group.
          </strong>
          <br />
          We only share your personal data with third parties for direct
          marketing purposes with your consent. In this context, your data is
          processed by such third party, acting as a data user, and its own
          terms and conditions and privacy notice apply. You should carefully
          check their documentation before consenting to the disclosure of your
          information to that third party.
          <p>
            <strong>
              Your personal data may also be processed on our behalf by our
              trusted third party providers.
            </strong>
            .
          </p>
          <p>
            We rely on trusted third parties to perform a range of business
            operations on our behalf. We only provide them with the information
            they need to perform the service, and we require that they do not
            use your personal data for any other purpose. We always use our best
            efforts to ensure that all third parties we work with keep your
            personal data secure. For instance, we may entrust services that
            require the processing of your personal data to:
          </p>
          <ul>
            <li>
              third parties that assist and help us in providing digital and
              e-commerce services such as social listening, store locator,
              loyalty programs, identity management, ratings and reviews, CRM,
              web analytics and search engine, user generated content curation
              tools;{' '}
            </li>
            <li>
              advertising, marketing, digital and social media agencies to help
              us to deliver advertising, marketing, and campaigns, to analyse
              their effectiveness, and to manage your contact and questions;
            </li>
            <li>
              third parties required to deliver a product to you e.g.
              postal/delivery services;
            </li>
            <li>
              third parties that assist and help us in providing IT services,
              such as platform providers, hosting services, maintenance and
              support on our databases as well as on our software and
              applications that may contain data about you (such services could
              sometimes imply access to your data to perform the required
              tasks);
            </li>
            <li>
              payment service providers and credit reference agencies for the
              purpose of assessing your credit score and verifying your details
              where this is a condition of entering into a contract with you;
              and
            </li>
            <li>
              third parties that assist us for customer care and post-market
              surveillance purposes.
            </li>
            <li>&nbsp;</li>
          </ul>
          <p>
            <strong>
              We may also disclose your personal data to third parties:
            </strong>
          </p>
          <ul>
            <li>
              in the event that we sell any business or assets, in which case we
              may disclose your personal data to the prospective buyer of such
              business or assets. If L’Oréal or a part of its assets is acquired
              by a third party, personal data held by it about its customers
              relating to those assets is one of the transferred assets. Where
              appropriate, in such case, the buyer acting as the new data user
              processes your data and its privacy policy governs the processing
              of your personal data;{' '}
            </li>
            <li>
              if we are under a duty to disclose or share your personal data in
              order to comply with a legal obligation, or in order to enforce or
              apply our terms of use/sales or other terms and conditions you
              have agreed to; or to protect the rights, property, or safety of
              L’Oréal, our customers, or employees{' '}
            </li>
            <li>if we have your consent to do so; or</li>
            <li>if we are permitted to do so by law.</li>
          </ul>
          <strong>We may disclose your personal data to our partners:</strong>
          <ul>
            <li>
              in the event the service you subscribe to was co-created by
              L’Oréal and a partner (for example, a co-branded app). In such
              case, L’Oréal and the partner process your personal data each for
              their own purposes and as such your data is processed:
              <ul>
                <li>by L’Oréal in accordance with this Privacy Policy; and</li>
                <li>
                  by the partner acting also as a data user under its own terms
                  and conditions and in accordance with its own privacy policy;{' '}
                </li>
              </ul>
            </li>
            <li>
              in the event you agreed to receive marketing and commercial
              communications from a L’Oréal partner through a dedicated opt-in
              (for instance, through an App branded by L’Oréal and made
              available to its partners). In such case, your data is processed
              by the partner acting as a data user under its own terms and
              conditions, and in accordance with its privacy policy; and
            </li>
            <li>
              we may publish on our supports content from social networks. In
              the event you consult content from social networks on our
              website/apps, a Cookie from such social network may be stored on
              your device. We invite you to read the Cookie Policy of these
              social networks for more information.
            </li>
          </ul>
          <strong>We do not offer or sell your personal data.</strong>
          <h2 id="where-we-store-your-personal-data">
            <p name="_Toc499630198">Where we Store your Personal data</p>
          </h2>
          <p>
            The data that we collect from you may be transferred to, accessed
            from, and stored at a destination outside Hong Kong SAR. It may also
            be processed by staff members operating outside the Hong Kong SAR
            who work for us or for one of our service providers.
          </p>
          <p>
            L’Oréal transfers personal data outside of the Hong Kong SAR only in
            a secure and lawful way. As some countries may not have laws
            governing the use and transfer of personal data, we take steps to
            make sure that third parties adhere to the commitments set out in
            this Policy. These steps may include reviewing third parties’
            privacy and security standards and/or entering into appropriate
            contracts.
            <br />
            For further information, please contact us as per the “Contact”
            section below.
          </p>
          <h2 id="how-long-do-we-keep-your-personal-data">
            How Long Do We Keep Your Personal data
          </h2>
          <p>
            We only keep your personal data for as long as we need it for the
            purpose for which we hold your personal data, to meet your needs, or
            to comply with our legal obligations.
            <br />
            To determine the data retention period of your data, we use the
            following criteria:
          </p>
          <ul>
            <li>
              where you purchase products and services, we keep your personal
              data for the duration of our contractual relationship;
            </li>
            <li>
              where you participate in a promotional offer, we keep your
              personal data for the duration of the promotional offer;
            </li>
            <li>
              where you contact us for an enquiry, we keep your personal data
              for the duration needed for the processing of your enquiry;
            </li>
            <li>
              where you create an account, we keep your personal data until you
              require us to delete it or after a period of inactivity (no active
              interaction with brands) defined in accordance with local
              regulations and guidance;
            </li>
            <li>
              where you have consented to direct marketing, we keep your
              personal data until you unsubscribe or require us to delete it or
              after a period of inactivity (no active interaction with brands)
              defined in accordance with local regulations and guidance; and
            </li>
            <li>
              where cookies are placed on your computer, we keep them for as
              long as necessary to achieve their purposes (e.g. for the duration
              of a session for shopping cart cookies or session ID cookies) and
              for a period defined in accordance with local regulations and
              guidance.{' '}
            </li>
          </ul>
          <p>
            We may retain some personal data to comply with our legal or
            regulatory obligations, as well as to allow us to manage our rights
            (for example to assert our claims in Courts) or for statistical or
            historical purposes.
          </p>
          <p>
            When we no longer need to use your personal data, it is removed from
            our systems and records or anonymised so that you can no longer be
            identified from it.
          </p>
          <h2 id="is-my-Personal-data-secure">
            <p name="_Toc499630200">Is Your Personal data Secure?</p>
          </h2>
          <p>
            We are committed to keeping your personal data secure, and taking
            all reasonable precautions to do so. We contractually require that
            trusted third parties who handle your personal data for us do the
            same.
          </p>
          <p>
            We always do our best to protect your personal data and once we have
            received your personal data, we use strict procedures and security
            features to try to prevent unauthorised access. As the transmission
            of information via the internet is not completely secure, we cannot
            guarantee the security of your data transmitted to our site. As
            such, any transmission is at your own risk.
          </p>
          <h2 id="links-to-third-party-sites">
            <p name="_Toc499630201">
              Links to Third Party Sites and Social Login
            </p>
          </h2>
          <p>
            Our websites and Apps may from time to time contain links to and
            from the websites of our partner networks, advertisers and
            affiliates. If you follow a link to any of these websites, please
            note that these websites have their own privacy policies and that we
            are not responsible or liable for these policies. Please check these
            policies before you submit any personal data to these websites.
          </p>
          <p>
            We may also offer you the opportunity to use your social media
            login. If you do so, please be aware that you share your profile
            information with us depending on your social media platform
            settings. Please visit the relevant social media platform and review
            its privacy policy to understand how your personal data is shared
            and used in this context.
          </p>
          <h2 id="social-media">
            <p name="_Toc499630202">Social Media and User Generated Content</p>
          </h2>
          <p>
            Some of our websites and Apps allow users to submit their own
            content. Please remember that any content submitted to one of our
            social media platforms can be viewed by the public, so you should be
            cautious about providing certain personal data e.g. financial
            information or address details. We are not responsible for any
            actions taken by other individuals if you post personal data on one
            of our social media platforms and we recommend that you do not share
            such information.
          </p>
          <h1 id="your-rights">YOUR RIGHTS AND CHOICES</h1>
          <p>
            L’Oréal respects your right to privacy: it is important that you are
            able to control your personal data. You have the following rights:
          </p>
          <table
            border="0"
            cellPadding="0"
            cellSpacing="0"
            className="table table-bordered table-responsive"
            width="0"
          >
            <tbody>
              <tr>
                <td valign="top">
                  <p>
                    <strong>
                      <em>Your rights</em>
                    </strong>
                  </p>
                </td>
                <td valign="top">
                  <p>
                    <strong>
                      <em>What does this mean?</em>
                    </strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <p>
                    The right to be <strong>informed</strong>
                  </p>
                </td>
                <td valign="top">
                  <p>
                    You have the right to obtain clear, transparent and easily
                    understandable information about how we use your personal
                    data, and your rights. This is why we are providing you with
                    the information in this Policy.
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <p>
                    The right of <strong>access</strong>
                  </p>
                </td>
                <td valign="top">
                  <p>
                    You have the right to access to the personal data we hold
                    about you (subject to certain restrictions).
                    <br />
                    We may charge a reasonable fee taking into account the
                    administrative costs of providing the information.
                    <br />
                    Requests manifestly unfounded, excessive or repetitive may
                    not be answered to. To do this, please contact us at the
                    details below.
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <p>
                    The right to <strong>rectification</strong>
                  </p>
                </td>
                <td valign="top">
                  <p>
                    You have the right to have your personal data rectified if
                    it is incorrect or outdated and/or completed if it is
                    incomplete.
                    <br />
                    To do this, please contact us at the details below. If you
                    have an account, please correct your own data via your “My
                    Account” function.
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <p>
                    The right to{' '}
                    <strong>
                      object to direct marketing, including profiling
                    </strong>
                  </p>
                </td>
                <td valign="top">
                  <p>
                    You can unsubscribe or opt out of our direct marketing
                    communication at any time.
                    <br />
                    It is easiest to do this by clicking on the “unsubscribe”
                    link in any email or communication we send you. Otherwise,
                    you can contact us using contact detail below.
                  </p>

                  <p>
                    If you would like to object to any profiling, please contact
                    us at the details below.
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <p>
                    The right to{' '}
                    <strong>
                      withdraw consent at any time for data processing based on
                      consent
                    </strong>
                  </p>
                </td>
                <td valign="top">
                  <p>
                    You can withdraw your consent to our processing of your data
                    when such processing is based on consent. The withdrawal of
                    consent shall not affect the lawfulness of processing based
                    on consent before its withdrawal. We refer to the table
                    inserted in section “what data do we collect from you and
                    how do we use it” especially the column “What is our legal
                    basis for processing your data?” to identify where our
                    processing is based on consent.
                    <br />
                    If you would like to object to withdraw your consent, please
                    contact us at the details below.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            To deal with your request, we may require proof of your identity.
          </p>
          <p>
            A Chinese version of this Privacy Policy is available. In the event
            of discrepancy, the English version shall prevail.
          </p>
          <p>24 June 2020</p>
          <br />
          <br />
          <h1 id="pics">PERSONAL INFORMATION COLLECTION STATEMENT</h1>
          <p>
            <b>Collection of Your Personal Data</b>
            <br />
            We, L’Oreal Hong Kong Limited (“<b>we</b>” or “<b>L’Oréal</b>”)
            trading as [BIOTHERM] (“<b>Brand</b>”), will collect and store your
            personal information pursuant to this Personal Information
            Collection Statement (“<b>Your Data</b>”). It is mandatory for you
            to provide your personal information marked with asterisks (*) and
            voluntary for those without an asterisk (*); but if you do not, we
            may not be able to provide you with our products and services.
          </p>
          <p>
            <b>Purpose for which Your Personal Data are Used</b>
            <br />
            We will use Your Data for the following purposes (as the case may
            be):
          </p>
          <ol>
            <li>
              fulfilling, managing and contacting you about your purchase of our
              goods and/or services at online or retail stores (including our
              retail/department store partners), and your online purchase
              accounts;
            </li>
            <li>
              creating, managing and contacting you about your Brand membership
              and member rewards (including enquiries and implementation on
              loyalty points accrual or redemption);
            </li>
            <li>
              providing you with free products, samples or gifts in relation to
              any contest, lucky draw, game, competition, event or promotion
              which is organised by L’Oréal as you may participate;
            </li>
            <li>
              communicating with you regarding your enquiries about our goods
              and/or services;
            </li>
            <li>
              identification and verification to facilitate any of the above
              purposes;
            </li>
            <li>internal research, profiling and analytics; </li>
            <li>
              (subject to any written consent you may give) direct marketing
              purpose; and
            </li>
            <li>any other directly related purposes</li>
          </ol>
          <p>
            (collectively, the “<b>Use Purposes</b>”).
            <br />
            Where you have given your written consent, we may contact and
            communicate with you by phone call, SMS, email, mail or via
            interactive conversations over social media platform messengers
            (e.g. Facebook message, Instagram message and WeChat message, etc.)
            with our beauty advisors.
          </p>
          <p>
            <b>Transfer of Your Personal Data</b>
            <br />
            For the Use Purposes, we may transfer, grant access to or share Your
            Data with:
          </p>
          <ol>
            <li>
              L’Oreal Hong Kong Limited (“<b>L’Oréal</b>”), or any member of its
              group companies or affiliates, whether located within or outside
              of Hong Kong SAR (together the “<b>L’Oréal Group</b>”);
            </li>
            <li>
              any of L’Oréal’s or L’Oréal Group member’s third party service
              providers or agents who provides payment, IT, research, profiling,
              analytics, marketing, call centre, administrative or other
              services which support the business operation of L’Oréal or any
              L’Oréal Group member;
            </li>
            <li>
              in relation to payment for your purchase of our goods and/or
              services, credit reference agencies, credit, debit and/or charge
              card companies and/or banks; and
            </li>
            <li>
              social media platform providers (including those which may be
              located in the PRC and the United States).
            </li>
          </ol>
          <p>
            <b>USE OF YOUR PERSONAL DATA FOR DIRECT MARKETING</b>
            <br />
            If you opt-in, we will use your full name, email address, telephone number, mail address, WhatsApp account name and account ID, WeChat account name and account ID, Facebook account name and account ID to send you promotions, news and updates regarding (as the case may be) beauty, skincare, make-up, hair care, hair styling, fragrances, scents, candles, hand wash and related products and services from our Brand in Hong Kong SAR and Macau SAR via the communication channels you indicate (e.g. phone call, SMS, email, mail, WhatsApp message, WeChat message, Facebook message etc.) We cannot use and/or transfer your personal information for direct marketing without your consent.
          </p>
          <p>
            <b>Your Rights and Contact Us</b>
            <br />
            You have the right to request access to or correction of information
            held by us about you. If you wish to access or correct your personal
            information, please contact our Legal Department at{' '}
            <a href="mailto:privacy.corphk@loreal.com">
              privacy.corphk@loreal.com
            </a>
            . For any unsubscribe from direct marketing or other general
            enquiries, please contact us at{' '}
            <a href="mailto:biotherm.csc@loreal.com<">
              biotherm.csc@loreal.com
            </a>
            <br />
            For further information on how we use your personal information,
            please see our Privacy Policy:{' '}
            <Link to={`/en${genderPath}/privacy`}>
              http://www.biotherm.com.hk/en{genderPath}/privacy
            </Link>
            .<br />
            This Statement is written in the English language and may be
            translated into the Chinese language. In the event of any
            inconsistency, the English version shall prevail.
          </p>
        </div>
      )}
    </div>
  );
};

export default PrivacyEn;
